<template>
  <div id="scheduledsubscription-list">
    <v-card>
      <v-card-title>定期便注文スケジュール</v-card-title>
      <v-row class="px-2 ma-0">
        <!-- <v-col cols="12" md="3">
          <v-text-field
            v-model="mbr_sei_kana"
            placeholder="会員セイ"
            outlined
            clearable
            dense
            hide-details
            @change="changeSeiKana"
          ></v-text-field>
        </v-col> -->
        <!-- <v-col cols="12" md="3">
          <v-text-field
            v-model="mbr_mei_kana"
            placeholder="会員メイ"
            outlined
            clearable
            dense
            hide-details
            @change="changeMeiKana"
          ></v-text-field>
        </v-col> -->

        <v-col cols="12" md="3">
          <v-autocomplete
            v-model="subs_code"
            :items="subsCodes"
            placeholder="定期便コード"
            outlined
            clearable
            dense
            hide-details
          ></v-autocomplete>
        </v-col>

        <v-col cols="12" md="3">
          <v-autocomplete
            v-model="sch_status_code"
            :items="orderStatusDesc"
            placeholder="注文状態"
            outlined
            clearable
            dense
            hide-details
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="3">
          <v-menu
            v-model="dateStartMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="startDate"
                placeholder="決済予定日From"
                dense
                hide-details
                outlined
                readonly
                v-bind="attrs"
                :prepend-icon="icons.mdiCalendar"
                clearable
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="startDate" @input="dateStartMenu = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="3">
          <v-menu
            v-model="dateEndMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="endDate"
                placeholder="発送予定日To"
                dense
                outlined
                readonly
                hide-details
                v-bind="attrs"
                :prepend-icon="icons.mdiCalendar"
                clearable
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="endDate"
              @input="dateEndMenu = false"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-divider class="mt-4"></v-divider>
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-text-field
          v-model="search"
          placeholder="Search"
          outlined
          hide-details
          dense
          class="me-3 mb-4"
        >
        </v-text-field>
        <v-spacer></v-spacer>
        <div class="d-flex align-center flex-wrap">
          <v-btn
            color="primary"
            class="mb-4 me-3"
            :to="{ name: 'scheduledsubscription-create', params: { query: this.$route.query } }"
          >
            <v-icon size="17" class="me-1">
              {{ icons.mdiPlus }}
            </v-icon>
            <span>新規</span>
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            class="mb-4 me-3"
          >
            <v-icon size="17" class="me-1">
              {{ icons.mdiExportVariant }}
            </v-icon>
            <span>Export</span>
          </v-btn>
        </div>
      </v-card-text>
      <v-data-table
        :headers="headers"
        :items="List"
        sort-by
        class="elevation-1"
        :search="search"
      >
        <template v-slot:[`item.sch_id`]="{ item }">
          <a
            href="javascript:void(0);"
            @click="pushOrderHistory(item)"
          >{{ item.sch_id }}</a>
        </template>
        <template v-slot:[`item.name`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <a
                v-bind="attrs"
                href="javascript:void(0);"
                :style="item.mbr_status === 9 ? 'text-decoration: line-through red; color:#696969' : 'text-decoration: underline; color:#696969' "
                v-on="on"
              >{{ item.name }}</a>
            </template>
            <span>{{ item.mbr_sei }}   {{ item.mbr_mei }}</span><br>
            <span>{{ item.mbr_sei_kana }}   {{ item.mbr_mei_kana }}</span><br>
            <span>{{ item.mbr_zip }}</span><br>
            <span>{{ item.mbr_address1 }}{{ item.mbr_address2 }}</span><br>
            <span>{{ item.mbr_email }}</span><br>
            <span>{{ item.mbr_mobile }}</span><br>
          </v-tooltip>
        </template>
        <template v-slot:[`item.sch_status_code`]="{ item }">
          <div>
            <span>{{ getStatu(item.sch_status_code) }}</span>
          </div>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex justify-end">
            <div class="py-2 pl-1">
              <v-icon
                small
                @click="editItem(item)"
              >
                {{ icons.mdiPencil }}
              </v-icon>
            </div>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import {
  mapState, mapActions, mapMutations,
} from 'vuex'
import {
  mdiCalendar,
  mdiPencil,
  mdiDelete,
  mdiPlus,
  mdiExportVariant,
} from '@mdi/js'

export default {
  components: {

  },
  data: () => ({
    startDate: '',
    endDate: '',
    dateEndMenu: false,
    dateStartMenu: false,
    mbr_mei_kana: '',
    mbr_sei_kana: '',
    subs_code: '',
    status: '',
    sch_status_code: '',
    orderStatusDesc: [
    ],
    List: [],
    search: '',
    icons: {
      mdiCalendar,
      mdiPencil,
      mdiDelete,
      mdiPlus,
      mdiExportVariant,
    },
    headers: [
      {
        text: '注文ID',
        value: 'sch_id',
        align: 'left',
        width: '8%',
        fixed: true,
      },

      {
        text: '会員氏名',
        align: 'left',
        sortable: true,
        value: 'name',
        width: '12%',
        fixed: true,
      },
      {
        text: '定期便コード',
        value: 'subs_code',
        width: '12%',
        align: 'left',
        sortable: true,
        fixed: true,
      },
      {
        text: '数量',
        value: 'order_qty',
        width: '8%',
        align: 'right',
        sortable: true,
        fixed: true,
      },
      {
        text: '元注文',
        value: 'origin_ord_id',
        width: '10%',
        align: 'right',
        sortable: true,
        fixed: true,
      },

      // {
      //   text: '注文日時',
      //   value: 'order_dt',
      //   width: '10%',
      //   align: 'left',
      //   sortable: true,
      //   fixed: true,
      // },
      {
        text: '決済予定日',
        value: 'sch_pud',
        width: '10%',
        align: 'left',
        sortable: true,
        fixed: true,
      },
      {
        text: '発送予定日',
        value: 'sch_ead',
        width: '10%',
        align: 'left',
        sortable: true,
        fixed: true,
      },

      {
        text: '定期便状態',
        value: 'sch_status_desc',
        width: '10%',
        align: 'center',
        sortable: true,
        fixed: true,
      },
      {
        text: '注文状態',
        value: 'os_desc',
        width: '10%',
        align: 'center',
        sortable: true,
        fixed: true,
      },
      {
        text: '　',
        value: 'actions',
        sortable: false,
        width: '5%',
        align: 'right',
        fixed: true,
      },
      {
        text: '姓',
        value: 'mbr_sei',
        align: ' d-none',
      },
      {
        text: '名',
        value: 'mbr_mei',
        align: ' d-none',
      },
      {
        text: '姓',
        value: 'mbr_sei_kana',
        align: ' d-none',
      },
      {
        text: '名',
        value: 'mbr_mei_kana',
        align: ' d-none',
      },
    ],
  }),
  computed: {
    ...mapState('scheduledSubsStore', ['scheduledList', 'SubsStatusList']),
    ...mapState('subscriptionStore', ['subsCodes']),

  },
  watch: {
    subs_code(newValue) {
      this.changeRouteQuery('subs_code', newValue)
      this.loadData()
    },
    sch_status_code(newValue) {
      this.changeRouteQuery('sch_status_code', newValue)
      this.loadData()
    },
    startDate(newValue) {
      this.changeRouteQuery('start_date_pud', newValue)
      this.loadData()
    },
    endDate(newValue) {
      this.changeRouteQuery('end_date_ead', newValue)
      this.loadData()
    },
  },
  created() {
    this.loadQuery()
    this.changeRouteQuery()
    this.loadData()
  },

  destroyed() {
    this.clearScheduledList()
    this.clearSubsStatusList()
    this.clearSubsCodes()
  },

  methods: {
    ...mapMutations('app', ['setOverlayStatus']),
    ...mapActions('scheduledSubsStore', ['loadScheduledList', 'loadSubsStatusList']),
    ...mapActions('subscriptionStore', ['loadSubscriptionCodes']),
    ...mapMutations('scheduledSubsStore', ['clearScheduledList', 'clearSubsStatusList']),
    ...mapMutations('subscriptionStore', ['clearSubsCodes']),
    changeSeiKana() {
      this.changeRouteQuery('mbr_sei_kana', this.mbr_sei_kana)
      this.loadData()
    },
    changeMeiKana() {
      this.changeRouteQuery('mbr_mei_kana', this.mbr_mei_kana)
      this.loadData()
    },
    pushOrderHistory(item) {
      this.$router.push({
        name: 'subscription-order-history',
        params: {
          id: item.sch_id,
        },
      })
    },
    loadData() {
      this.setOverlayStatus(true)
      Promise.all([
        this.loadSubscriptionCodes(),
        this.loadSubsStatusList(),
        this.loadScheduledList(this.$route.query),
      ]).then(() => {
        this.List = this.scheduledList.map(s => {
          const obj = s
          obj.name = `${s.mbr_sei} ${s.mbr_mei}`

          return obj
        })
      }).catch(error => {
        console.log('loadData', error)
      }).finally(() => {
        this.setOverlayStatus(false)
      })
    },

    editItem(item) {
      console.log('item', item)

      this.$router.push({
        name: 'scheduledsubscription-edit',
        params: {
          id: item.sch_id,
        },
      })
    },
    changeRouteQuery(key, val) {
      const query = JSON.parse(JSON.stringify(this.$route.query))
      if (val) {
        query[key] = val
      } else {
        delete query[key]
      }

      this.$router.push({
        path: this.$route.path, query,
      })
    },

    loadQuery() {
      this.mbr_sei_kana = this.$route.query.mbr_sei_kana ?? ''
      this.mbr_mei_kana = this.$route.query.mbr_mei_kana ?? ''
      // eslint-disable-next-line radix
      this.sch_status_code = this.$route.query.sch_status_code ? parseInt(this.$route.query.sch_status_code) : ''
      this.startDate = this.$route.query.start_date_pud ?? ''
      this.endDate = this.$route.query.end_date_ead ?? ''
    },

    // getStatuItem() {
    //   this.orderStatusDesc = this.SubsStatusList.map(x => {
    //     const obj = {
    //     }
    //     obj.text = x.subs_status_desc
    //     obj.value = x.subs_status_code

    //     return obj
    //   })
    // },

    getStatu(item) {
      for (let i = 0; i < this.SubsStatusList.length; i += 1) {
        if (item === this.SubsStatusList[i].subs_status_code) {
          return this.SubsStatusList[i].subs_status_desc
        }
      }

      return ''
    },
  },
}
</script>
<style>
.table-filed {
  white-space: normal;
}
.text-format {
  text-align: right !important;
}
.v-input__prepend-outer {
  margin: auto !important;
}
.text-field-dense-append-prepend-margin {
  margin: auto;
}
.v-input__append-inner {
  margin: auto !important;
}
/* .v-data-table tr:nth-child(odd) td {
  background-color: #F0FFFF;
} */
</style>
